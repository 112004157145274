export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'BarChartIcon',
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Fulfillment',
    route: 'fulfillment',
    icon: 'BoxIcon',
  },
  {
    title: 'Locations',
    route: 'locations',
    icon: 'HomeIcon',
  },
  {
    title: 'Item Groups',
    route: 'item-groups',
    icon: 'LayersIcon',
  },
  {
    title: 'Items',
    route: 'items',
    icon: 'ToolIcon',
  },
  {
    title: 'Item Categories',
    route: 'item-categories',
    icon: 'ArchiveIcon',
  },
  {
    title: 'Discounts & Fees',
    route: 'discounts&fees',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'App Settings',
    route: 'app-settings',
    icon: 'SettingsIcon',
  },
]
