import RoleTypes from '@/enums/RoleTypes'
import SessionService from '@/services/sessionService'
import adminNav from '../items/adminNav'
import customerNav from '../items/customerNav'
import workerNav from '../items/workerNav'

export default function menuItems() {
  switch (SessionService.getLoggedInUser().role?.get()) {
    case RoleTypes.Admin.get():
      return adminNav
    case RoleTypes.Customer.get():
      return customerNav
    case RoleTypes.Worker.get():
      return workerNav
    default:
      return []
  }
}
